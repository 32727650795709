import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
    <LessonLayout
      current_step={3}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 3</H2>

        <p>How does the type of information in these ads compare with the type of information you would include with the product you wanted to sell (at the beginning of this lesson)?</p>
        <p>Think about the following questions and discuss them with your teammates.</p>

        <UnorderedList>
          <li>Can people who write these commercials (or ads) say anything they want?</li>
          <li>Why would commercials include information about what could go wrong if someone takes it?</li>
        </UnorderedList>

        <p>Be ready to participate in a class discussion.</p>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

